<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="选项">
            <el-card class="box-card">
              <i
                style="float: right"
                @click="addOption()"
                class="el-icon-circle-plus-outline"
              ></i>
              <div
                v-for="o in form.optionList"
                :key="o.value"
                class="text item"
                style="margin-top: 20px"
              >
                <el-row>
                  <el-col :span="2">
                    <el-input v-model="o.value"></el-input>
                  </el-col>
                  <el-col :span="20" :offset="1">
                    <el-input v-model="o.text"></el-input>
                  </el-col>
                  <el-col :span="1">
                    <i
                      style="margin-left: 10px"
                      class="el-icon-remove-outline"
                      @click="delRow(o.value)"
                    ></i>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </el-form-item>

          <el-form-item label="是否附加描述">
            <el-switch v-model="form.isAttach"></el-switch>
          </el-form-item>
          <div v-if="form.isAttach">
            <el-form-item label="附加描述默认提示">
              <el-input v-model="form.attachTitle"></el-input>
            </el-form-item>
            <el-form-item label="触发描述选项">
              <el-checkbox-group v-model="form.attachOptionRuleArray">
                <el-checkbox
                  v-for="k in form.optionList"
                  :key="k.value"
                  :label="k.value"
                  name="attachOptionRuleArray"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
          <el-form-item style="float:right">
            <el-button type="danger" @click="onDelete" v-if="form.guid !== ''"
              >删除</el-button
            >
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.card_style {
  margin-top: 20px;
  padding-left: 40px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      form: {},
      userInfo: {},
      rules: {},
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getOptionDetail(self.$route.query.guid || null);
  },
  methods: {
    getOptionDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/option-group/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.form = response.data.data;
            if (self.form.optionList == undefined) {

              let temp = {};
              self.form.optionList.push(temp);
            }
          })
          .catch(function (error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      } else {
        self.form = {
          optionList:[],
          attachOptionRuleArray:[]
        };
      }
    },
    delRow(_value) {
      let self = this;
      self.form.optionList = self.form.optionList.filter(
        ({ value }) => value !== _value
      );
    },
    addOption() {
      let self = this;
      let temp = {};
      temp.value = "";
      temp.text = "";
      if (self.form.optionList == undefined) {
        self.form.optionList = [];
      }

      var index = self.form.optionList.findIndex((o) => o.value == "");
      if (index != -1) {
        self.$message({
          message: "请先将空行填写完整",
          type: "warning",
        });
      } else {
        self.form.optionList.push(temp);
      }
    },
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/option-group/saveOrUpdate", JSON.stringify(data), {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function () {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "option_group_list" });
            })
            .catch(function (error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的选项信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    onDelete() {
      let self = this;
      self
        .$confirm("确定删除 " + (self.form.name || "未命名选项组") + "？")
        .then(() => {
          self.$axios
            .delete("/option-group/delete", {
              data: { guid: self.form.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.$router.push({ name: "option_group_list" });
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>